








import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import BlurredTenant from '@/components/BlurredTenant.vue';
import * as _ from 'lodash';




export default Vue.extend({
    components: { BlurredTenant },
    data() {
        return {
            showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "createdTime",
                    "sort-desc": true,
                },
                content: {
                    name: "Charging Membership History",
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Organization",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("tenants", "findAll", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "name",
                                                        orderType: "asc",
                                                        filters: [
                                                            {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                            },
                                                        ],
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            "targetAfter.userId": userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "User",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "email",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("users", "findAllUser", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "email",
                                                        orderType: "asc",
                                                        filters: [
                                                            {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                            },
                                                        ],
                                                        extraParams: {
                                                            searchCrossTenant: !!search && true
                                                        }
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            actionType: {
                                type: "select",
                                attrs: {
                                    label: "Action Type",
                                    items: [
                                        { text: "Create", value: "Create" },
                                        { text: "Update", value: "Update" },
                                        { text: "Delete", value: "Delete" },
                                    ],
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            createdTime: {
                                type: "XDateTimePicker",
                                rules: {
                                    greater_than_or_equal_to: {
                                        attrs: {
                                            label: "Action Time (From)",
                                        },
                                    },
                                    less_than_or_equal_to: {
                                        attrs: {
                                            label: "Action Time (To)",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    displayFields: {
                        user: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                            text: "User",
                            options: {
                                label: true,
                                transform: (data, item) => {
                                    return item?.targetAfter?.user?.email || item?.targetBefore?.user?.email;
                                },
                            },
                        },
                        phone: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                            text: "Phone",
                            options: {
                                label: true,
                                transform: (data, item) => {
                                    return item?.targetAfter?.user?.phone || item?.targetBefore?.user?.phone;
                                },
                            },
                        },
                        actionBy: {
                            text: "Action By",
                            options: {
                                subProp: "user.email",
                                label: true,
                            },
                        },
                        actionType: {
                            text: "Action Type",
                            options: {
                                label: true,
                                attrs(value) {
                                    const colors = {
                                        Create: "green",
                                        Update: "blue",
                                        Delete: "red",
                                    };
                                    return {
                                        color: colors[value] || "gray"
                                    };
                                },
                            },
                        },
                        before: {
                            text: "Before",
                            options: {
                                transform: (data, item) => {
                                    return this.formatData(item.targetBefore);
                                },
                            },
                        },
                        after: {
                            text: "After",
                            options: {
                                transform: (data, item) => {
                                    return this.formatData(item.targetAfter, item.targetBefore);
                                },
                            },
                        },
                        createdTime: {
                            text: "Action Time",
                            options: {
                                transform: (data) => {
                                    return new Date(data).toLocaleString("en-GB");
                                },
                            },
                        },
                    },
                    topActionButtons: {
                        export: userManager.checkRole([
                            "systemAdmin",
                            "admin",
                            "cpoAdmin", "cpoMonitor",
                            "retailer",
                        ]) && {
                            content: {
                                text: "Export all",
                                icon: "mdi-microsoft-excel",
                            },
                            on: {
                                xClick({ self }) {
                                    const dataTable = self.context();
                                    coreApiClient.download(
                                        "actionLogs",
                                        `@/export/memberShipHistory`,
                                        dataTable.getFindAllOptions()
                                    );
                                },
                            },
                        },
                    },
                    findAllOptionsFilter(options) {
                        options.filters.push({
                            key: "targetType",
                            operator: "equal_to",
                            value: 'ChargingMembership',
                        });
                        return options;
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const { items, count } = await coreApiClient.call("actionLogs", "findAll", options);
                            return [items, count];
                        }
                    }
                },
            },
        };
    },
    methods: {
        getDifferences(obj1, obj2) {
            const differences = {};
            _.union(_.keys(obj1), _.keys(obj2)).forEach((key) => {
                if (!_.isEqual(obj1[key], obj2[key])) {
                    differences[key] = true;
                }
            });
            return differences;
        },
        differenceColor(isDifferent) {
            return isDifferent ? 'style="color: red;"' : '';
        },
        formatData(data: any = {}, before) {
            const compareData = [
                'type', 'subType', 'discount', 'maximumCycles',
                'maximumConsumptionKWh', 'startDate', 'endDate',
                'chargingStationNames', 'disabled'
            ];

            if (data.chargingStations) {
                data.chargingStationNames = _.map(data.chargingStations, 'uniqueId');
            }
            if (before?.chargingStations) {
                before.chargingStationNames = _.map(before.chargingStations, 'uniqueId');
            }

            data = _.pick(data, compareData);
            let differences = {};
            if (before) {
                before = _.pick(before, compareData);
                differences = this.getDifferences(before, data);
            }

            const subTypeMap = {
                all: 'All',
                tenant: 'From Organization',
                charging_station: 'From Charging Station',
            };

            const formatField = (label, field, formatter = (v) => v || '') => {
                if (data[field] == null) return '';
                return `
                <div ${this.differenceColor(differences[field])}>
                    <b>${label}:</b> ${formatter(data[field])}
                </div>
            `;
            };

            return `
            <div>
                ${formatField('Type', 'type', (v) => _.upperFirst(v))}
                ${formatField('Sub Type', 'subType', (v) => subTypeMap[v] || v)}
                ${formatField('Discount(%)', 'discount')}
                ${formatField('Maximum Cycles Per Day', 'maximumCycles')}
                ${formatField('Maximum Consumption KWh', 'maximumConsumptionKWh')}
                ${formatField('Start Date', 'startDate', (v) => new Date(v).toLocaleDateString("en-GB"))}
                ${formatField('End Date', 'endDate', (v) => new Date(v).toLocaleDateString("en-GB"))}
                ${formatField('Charging Station', 'chargingStationNames', (v) => v.join(', '))}
                ${formatField('Disabled', 'disabled', (v) => (v ? 'Disabled' : 'Enabled'))}
            </div>
        `;
        },
    }
});

