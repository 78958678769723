








import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
    components: { BlurredTenant },
    data() {
        const subTyleItems = userManager.checkRole(["cpoAdmin", "cpoMonitor"], false, true) ? [
            { "text": "Tenant", "value": "tenant" },
            { "text": "Charging Station", "value": "charging_station" },
        ] : [
            { "text": "All", "value": "all" },
            { "text": "Tenant", "value": "tenant" },
            { "text": "Charging Station", "value": "charging_station" },
        ];
        const typeItems = [
            { "text": "Free", "value": "free" },
            { "text": "Discount", "value": "discount" },
        ];
        return {
            showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "licensePlate",
                    "sort-desc": false,
                },
                content: {
                    name: "Charging Membership",
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Organization",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "name",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("tenants", "findAll", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "name",
                                                        orderType: "asc",
                                                        filters: [
                                                            {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                            },
                                                        ],
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            userId: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "User",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": "email",
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("users", "findAllUser", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "email",
                                                        orderType: "asc",
                                                        filters: [
                                                            {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                            },
                                                        ],
                                                        extraParams: {
                                                            searchCrossTenant: !!search && true
                                                        }
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            type: {
                                type: "select",
                                attrs: {
                                    label: "Type",
                                    items: typeItems,
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            subType: {
                                type: "select",
                                attrs: {
                                    label: "Sub Type",
                                    items: subTyleItems,
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            disabled: {
                                type: "select",
                                attrs: {
                                    label: "Disabled",
                                    items: [
                                        { text: "Disabled", value: true },
                                        { text: "Enable", value: false },
                                    ],
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            startDate: {
                                type: "XDateTimePicker",
                                rules: {
                                    greater_than_or_equal_to: {
                                        attrs: {
                                            label: "Start Time (From)",
                                        },
                                    },
                                    less_than_or_equal_to: {
                                        attrs: {
                                            label: "Start Time (To)",
                                        },
                                    },
                                },
                            },
                            endDate: {
                                type: "XDateTimePicker",
                                rules: {
                                    greater_than_or_equal_to: {
                                        attrs: {
                                            label: "End Time (From)",
                                        },
                                    },
                                    less_than_or_equal_to: {
                                        attrs: {
                                            label: "End Time (To)",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    displayFields: {
                        user: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                            text: "User",
                            options: {
                                subProp: "user.email",
                                label: true,
                            },
                        },
                        phone: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                            text: "Phone",
                            options: {
                                subProp: "user.phone",
                                label: true,
                            },
                        },
                        subType: {
                            text: "Sub Type",
                            options: {
                                transform: (value) => {
                                    switch (value) {
                                        case "all":
                                            return "All";
                                        case "tenant":
                                            return "From Organization";
                                        case "charging_station":
                                            return "From Charging Station";
                                        default:
                                            return value;
                                    }
                                },
                                label: true,
                            },
                        },
                        type: {
                            text: "Type",
                            options: {
                                transform: (value) => {
                                    switch (value) {
                                        case "free":
                                            return "Free";
                                        case "discount":
                                            return "Discount";
                                        default:
                                            return value;
                                    }
                                },
                                label: true,
                            },
                        },
                        discount: {
                            type: "number",
                            attrs: {
                                label: "Discount (%)",
                            },
                            rules: {
                                equal_to: {},
                            },
                        },
                        maximumCycles: {
                            type: "number",
                            text: "Maximum cycles per Day",
                            attrs: {
                                label: "Maximum cycles per Day",
                            },
                            rules: {
                                equal_to: {},
                            },
                        },
                        maximumConsumptionKWh: {
                            type: "number",
                            text: "Maximum consumption KWh",
                            attrs: {
                                label: "Maximum consumption KWh",
                            },
                            rules: {
                                equal_to: {},
                            },
                        },
                        startDate: {
                            text: "Start Date",
                            options: {
                                transform: (data) => {
                                    return new Date(data).toLocaleDateString("en-GB");
                                },
                            },
                        },
                        endDate: {
                            text: "End Date",
                            options: {
                                transform: (data) => {
                                    return new Date(data).toLocaleDateString("en-GB");
                                },
                            },
                        },
                        chargingStations: {
                            text: "Charging Stations",
                            options: {
                                labels(value) {
                                    return value && value.map((item) => `${item.uniqueId}`);
                                },
                            },
                        },
                        disabled: {
                            text: "Disabled",
                            options: {
                                label(value) {
                                    return (value && "Disabled") || "Enable";
                                },
                                attrs(value) {
                                    return {
                                        color: value ? "red" : "green",
                                    };
                                },
                            },
                        },
                        createdTime: {
                            text: "Created Time",
                            options: {
                                transform: (data) => {
                                    return new Date(data).toLocaleString("en-GB");
                                },
                            },
                        },
                        action: {
                            text: "Actions",
                        },
                    },
                    topActionButtons: {
                        history: userManager.checkRole([
                            "systemAdmin",
                            "admin",
                            "cpoAdmin", "cpoMonitor",
                            "retailer",
                        ]) && {
                            content: {
                                text: "History",
                                icon: "mdi-history",
                            },
                            on: {
                                xClick({ self }) {
                                    self.$router.push(`/app/charging_memberships_history`)
                                },
                            },
                            ext: {
                                sortPriority: -1
                            },
                        },
                        export: userManager.checkRole([
                            "systemAdmin",
                            "admin",
                            "cpoAdmin", "cpoMonitor",
                            "retailer",
                        ]) && {
                            content: {
                                text: "Export all",
                                icon: "mdi-microsoft-excel",
                            },
                            on: {
                                xClick({ self }) {
                                    const dataTable = self.context();
                                    coreApiClient.download(
                                        "chargingMemberships",
                                        `@/export`,
                                        dataTable.getFindAllOptions()
                                    );
                                },
                            },
                        },
                        insert: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {},
                    },
                    template: {
                        itemActionButtons: {
                            edit: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                                target: {
                                    dialog: {
                                        ext: {
                                            subTitleMaker: "user.email",
                                        },
                                    },
                                },
                                ext: {
                                    condition: (_, item) => {
                                        return !item.maximumConsumptionKWh
                                    }
                                }
                            },
                            delete: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {},
                        },
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const { items, count } = await coreApiClient.call("chargingMemberships", "findAll", options);
                            return [items, count];
                        },
                        async insert(item) {
                            return await coreApiClient.call("chargingMemberships", "create", undefined, item);
                        },
                        async update(item) {
                            return await coreApiClient.call(
                                "chargingMemberships",
                                "update",
                                {
                                    id: item._id,
                                },
                                {
                                    data: item,
                                }
                            );
                        },
                        async delete(item) {
                            return await coreApiClient.call("chargingMemberships", "delete", {
                                id: item._id,
                            });
                        },
                    },
                    default: {
                        defaultInputs: {
                            userId: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "User",
                                    required: true,
                                    "item-value": "_id",
                                    "item-text": (item) => `${item.email} (${item.phone})`,
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const filters = [
                                                    {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                    },
                                                ];
                                                const { items } = await coreApiClient.call(
                                                    "users",
                                                    "findAllUser",
                                                    {
                                                        payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            orderBy: "name",
                                                            orderType: "asc",
                                                            filters,
                                                        }),
                                                    }
                                                );
                                                return items;
                                            },
                                        },
                                    },
                                },
                            },
                            type: {
                                type: "select",
                                attrs: {
                                    label: "Type",
                                    required: true,
                                    items: typeItems,
                                },

                            },
                            subType: {
                                type: "select",
                                attrs: {
                                    label: "Sub Type",
                                    required: true,
                                    items: subTyleItems,
                                },
                            },
                            discount: { // 0 -> 100
                                type: "number",
                                attrs: {
                                    label: "Discount (%)",
                                    required: true,
                                    rules: [
                                        (v) => (v >= 1 && v <= 99) || "Discount must be between 1 and 99.",
                                    ],
                                },
                                ext: {
                                    defaultValue: "1",
                                }
                            },
                            maximumCycles: {
                                type: "number",
                                attrs: {
                                    label: "Maximum Cycles per Day",
                                    required: true,
                                },
                                ext: {
                                    defaultValue: 1,
                                }
                            },
                            maximumConsumptionKWh: {
                                type: "number",
                                text: "Maximum consumption KWh",
                                attrs: {
                                    label: "Maximum consumption KWh",
                                },
                                rules: {
                                    equal_to: {},
                                },
                            },
                            startDate: {
                                type: "XDateTimePicker",
                                attrs: {
                                    label: "Start Date",
                                    required: true,
                                },
                            },
                            endDate: {
                                type: "XDateTimePicker",
                                attrs: {
                                    label: "End Date",
                                    required: true,
                                },
                            },
                            chargingStationIds: {
                                type: "XAutocomplete",
                                attrs: {
                                    label: "Charging Station",
                                    multiple: true,
                                    "item-value": "_id",
                                    "item-text": (item) => `${item.uniqueId}`,
                                    xOptions: {
                                        content: {
                                            async itemsSuggestor(search, value, limit) {
                                                const filters = [
                                                    {
                                                        key: "_id",
                                                        operator: "in",
                                                        value,
                                                    }
                                                ];
                                                if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], false, true)) {
                                                    filters.push({
                                                        key: "tenantId",
                                                        operator: "equal_to",
                                                        value: userManager.getTenantId(),
                                                    });
                                                }
                                                const { items } = await coreApiClient.call("chargingStations", "findAll", {
                                                    payload: JSON.stringify({
                                                        limit,
                                                        search,
                                                        orderBy: "name",
                                                        orderType: "asc",
                                                        filters,
                                                    }),
                                                });
                                                return items;
                                            },
                                        },
                                    },
                                },
                            },
                            disabled: {
                                type: "boolean",
                                attrs: {
                                    label: "Disabled",
                                },
                            },
                        },
                        insertForm: {
                            content: {
                                fieldNames: [
                                    "userId",
                                    "type",
                                    "subType",
                                    "discount",
                                    "maximumCycles",
                                    "maximumConsumptionKWh",
                                    "startDate",
                                    "endDate",
                                    "disabled",
                                    "chargingStationIds",
                                ],
                            },
                        },
                        editForm: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
                            content: {
                                fieldNames: ["userId",
                                    "type",
                                    "subType",
                                    "discount",
                                    "maximumCycles",
                                    "maximumConsumptionKWh",
                                    "startDate",
                                    "endDate",
                                    "disabled",
                                    "chargingStationIds", ],
                            },
                        },
                        delete: {
                            confirmDisplayField: "_id",
                        },
                    },
                },
            },
        };
    },
});

